<template>
  <!-- 我的 -->
  <div class="page-mine">
    <div class="m-box">
      <van-cell-group inset>
        <van-cell @click="JumpLink('/auth')" title="我的实名" is-link/>
        <van-cell @click="JumpLink('/MyBank')" title="我的银行卡" is-link/>
      </van-cell-group>
    </div>

    <div v-if="env != 'APP'" class="btn" @click="logout">退出登录</div>

    <a href="tel:19557109374" class="customer">
      <img src="./img/customer.png" alt=""/>
      <span>联系客服</span>
    </a>

  </div>
</template>

<script>
import { toRefs, onMounted, reactive } from 'vue'
import { Toast } from 'vant'
import { Jump } from '@/utils/routh'
import { useRouter } from 'vue-router'
import API from '@/server/api'
import Store from '../../store'

export default {
  setup() {
    const env = localStorage.getItem('env') || 'h5'
    const state = reactive({ realNameState: false })

    const Router = useRouter()

    // 页面跳转
    const JumpLink = (url) => {
      switch (url) {
        case '/auth':
          if (state.realNameState) {
            Toast('您已实名，请勿重复操作~')
          } else {
            Jump(url)
          }
          break
        default:
          Jump(url)
          break
      }
    }

    // 初始化
    const init = async () => {
      try {
        const res = await API.isUser()
        if (res.success) {
          const { realNameState } = res.data
          state.realNameState = realNameState
        }
      } catch (err) {
        console.log('初始化用户授信报错')
      }
    }

    onMounted(async () => {
      await init()
    })

    const logout = () => {
      let env = localStorage.getItem('env') || ''
      window.localStorage.clear()
      localStorage.setItem('env', env)
      Store.dispatch('route/SetNavBar', false)
      Store.dispatch('route/SetTabBar', false)
      Router.replace('/login')
    }

    return {
      env,
      ...toRefs(state),
      JumpLink,
      logout
    }
  }
}
</script>

<style lang="less" src="./index.less" scoped></style>
<style lang="less" scoped>
.btn {
  width: 245px;
  height: 76px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 28px;
  color: #9B9B9B;
}
</style>
